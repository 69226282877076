/** @jsx jsx */
import React from "react";
import { Box, jsx, Image, Flex } from "theme-ui";
import bannerImage from "../../static/imgs/pvsindhu-badminton-img.png";
import badminton from "../../static/imgs/badminton-icon-img.png";
import badmintonSec1 from "../../static/imgs/badminton-sec1-img.png";
import badmintonSec2 from "../../static/imgs/badminton-sec2-img.png";
import background from "../../static/imgs/badminton-bg-image.png";
import pvsindu from "../../static/imgs/pvsindhu-img.png";
import badmintonDaaji from "../../static/imgs/badminton-daaji-img.png";
import LayoutHome from "../layout/LayoutHome";

const SindhuStadium = () => {
  const renderContentHeader = () => (
    <Box
      sx={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        padding: "60px 0px 110px",
      }}
    >
      <Flex
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Image
          sx={{ display: ["none", null, "block"], margin: "0 10px" }}
          src={bannerImage}
          alt="banner"
        />
        <Box sx={{ color: "#fff", p: { letterSpacing: "0.4px" } }}>
          <p sx={{ fontSize: "30px" }}>
            Support the <span sx={{ fontSize: "43.2px" }}>P.V.Sindhu</span>
          </p>
          <Image src={badminton} alt="badminton" />
          <p sx={{ fontSize: "36px" }}>Academy & Stadium</p>
          <button
            type="button"
            sx={{
              background: "#fff",
              padding: "8px 25px",
              borderRadius: "24px",
              margin: "15px 0",
              outline: "none",
              border: "none",
              color: "#026944",
              ":hover": { background: "#b66cbd" },
            }}
          >
            Donate Now
          </button>
        </Box>
      </Flex>
    </Box>
  );

  return (
    <LayoutHome
      seoTitle="P.V.Sindhu -Donations"
      contentHeader={renderContentHeader()}
    >
      <React.Fragment>
        <Box
          sx={{
            textAlign: "center",
            marginTop: "3rem",
            boxShadow: "0px 10px 30px #93939329",
            p: "20px 40px",
            borderRadius: "20px",
            mr: "1rem",
          }}
        >
          <p>
            With the laying of The Foundation Stone on 19 February, 2020 by
            champion badminton player, P.V. Sindhu, the Heartfulness Institute
            unveiled its plans to set up a state-of-theart badminton facility:
            the P.V. Sindhu Badminton Academy and Stadium. The facility is
            expected to be ready within the next 18 to 24 months, and is
            intended to be one of the best badminton academies in the world.
          </p>
          <p>
            The facility is located at the Omega International School in
            Kolapakkam Chennai, and will be open to aspiring players from both
            the school and the public.
          </p>
        </Box>
        <Flex
          sx={{
            justifyContent: "space-between",
            flexDirection: ["column", null, "row"],
            mb: 5,
            mt: 4,
          }}
        >
          <Box
            sx={{
              boxShadow: "0px 10px 30px #93939329",
              p: "20px 40px",
              borderRadius: "20px",
              mr: "1rem",
            }}
          >
            <Image
              sx={{ height: "400px", margin: "0px auto" }}
              src={badmintonSec1}
              alt="section1"
            />
            <h4 sx={{ fontSize: "32px", color: "#084469" }}>Facilities</h4>
            <p
              sx={{
                borderBottom: "3px solid #084469",
                height: "4px",
                width: "80px",
                display: "block",
                padding: "4px",
                marginBottom: "18px",
              }}
            />
            <p>
              The academy will support over eight courts built to match Olympic
              and World Championship courts. It will host a viewing gallery to
              seat an audience of over 1000 people, a gym and physio centre, and
              an area to practice both yoga and meditation.
            </p>
          </Box>
          <Box
            sx={{
              boxShadow: "0px 10px 30px #93939329",
              p: "20px 40px",
              borderRadius: "20px",
            }}
          >
            <Image
              sx={{ height: "400px", margin: "0px auto" }}
              src={badmintonSec2}
              alt="section2"
            />
            <h4 sx={{ fontSize: "32px", color: "#084469" }}>Training</h4>
            <p
              sx={{
                borderBottom: "3px solid #084469",
                height: "4px",
                width: "80px",
                display: "block",
                padding: "4px",
                marginBottom: "18px",
              }}
            />
            <p>
              World class badminton coaches, physical endurance and health
              trainers, and yoga experts and Heartfulness Meditation trainers
              will help players improve their badminton game, and will provide
              them with tools to strengthen their emotional and mental
              endurance.
            </p>
            <p>
              “The sport of badminton will get a tremendous boost and
              encouragement with this facility that will not only train players,
              but has plans to bring in national and international tournaments”,
              said P.V. Sindhu.
            </p>
          </Box>
        </Flex>
        <Box sx={{ background: "#eef8ff", padding: "40px" }}>
          <Flex
            sx={{
              flexDirection: ["column", null, "row"],
              justifyContent: "space-around",
              background: "#eef8ff",
              padding: "40px",
            }}
          >
            <Image
              sx={{
                flexShrink: 0,
                flex: 1,
                margin: "0 15px",
                alignSelf: "flex-start",
                pb: "20px",
              }}
              src={pvsindu}
              alt="pvsindu"
            />
            <Box sx={{ flex: 1 }}>
              <p>
                “I am honoured to have this facility named after me. Pursuing a
                sport is beneficial for the all-round development of an
                individual”, continued Sindhu. “At competitive levels it is very
                challenging, and we sportspersons need to remain fit, not just
                physically, but also emotionally and mentally. It’s not enough
                to regain the centre in the court after every shot; it’s more
                important to remain centred in the mind irrespective of what is
                happening in the game. This is the real game changer. The
                experience of Heartfulness over the last one year has brought a
                tremendous difference to my ability to remain focused in the
                moment.”
              </p>
              <b>– P.V.Sindhu</b>
            </Box>
          </Flex>
          <Flex
            sx={{
              flexDirection: ["column-reverse", null, "row"],
              justifyContent: "space-around",
              background: "#eef8ff",
              padding: "40px",
            }}
          >
            <Box sx={{ flex: 1, pt: "20px" }}>
              <p>
                “Hearing P.V. Sindhu’s experience of how the practice of
                Heartfulness elevated her game, we have been motivated to think
                in the direction of setting up this academy. Not only will this
                encourage and nurture our local talent in this sport, but those
                interested in badminton will have the Heartfulness edge.”
              </p>
              <b>– Heartfulness Guide, Kamlesh Patel</b>
            </Box>
            <Image
              sx={{
                flexShrink: 0,
                flex: 1,
                margin: "0 15px",
                alignSelf: "flex-start",
                py: "20px",
              }}
              src={badmintonDaaji}
              alt="pvsindu"
            />
          </Flex>
        </Box>
        <h4 sx={{ textAlign: "center", mt: 4 }}>
          We request your generous donations to fund this cause
        </h4>
        <button
          type="button"
          sx={{
            background: "#0C496E",
            padding: "8px 25px",
            borderRadius: "24px",
            margin: "15px 0",
            outline: "none",
            border: "none",
            color: "white",
            ":hover": { background: "#b66cbd" },
          }}
        >
          CONTRIBUTE
        </button>
      </React.Fragment>
    </LayoutHome>
  );
};

export default SindhuStadium;
